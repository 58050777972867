<template>
    <div class="container">
        <h4 class="font-weight-bolder py-md-3 py-3 sec-title">{{title}}</h4>
        <div class="row py-3 justify-content-around">
            <div class="col-md-4 p-2 col-12">
                <slot name="card1"></slot>
            </div>
            <div class="col-md-4 p-2 col-12">
                <slot name="card2"></slot>
            </div>
            <div class="col-md-4 p-2 col-12">
                <slot name="card3"></slot>
            </div>
        </div>
        <slot></slot>
    </div>
</template>

<script>
  export default {
    name: "Exhibit",
    props: ["title"],
  }
</script>

<style scoped>

</style>
