<template>
    <div class="row py-2 my-lg-3 justify-content-center">
        <div class="col-sm-2 col-4">
            <img class="p-2 img-fluid" :src="img">
        </div>
        <div class="col-sm-9 col-10 text-left">
            <div class="slogan-text font-weight-bold pb-3">{{title}}</div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
  export default {
    name: "RowDeck",
    props: ["img", "title"]
  }
</script>

<style scoped lang="scss">
    img {
        height: 80px;
    }

    @include media-breakpoint-up(lg) {
        img {
            height: 80px;
        }
    }
</style>
