<template>
    <div class="hello">
        <main role="main">
            <div class="container py-md-5 py-3 justify-content-around">
                <Splash>
                    <div slot="slogan">Company Formation Made Easy</div>
                    <div slot="slogan2">
                        <small>For Non-US Resident</small>
                    </div>
                    <div slot="slogan3">AILaw takes care of your legal concerns so that you can focus on the success of
                        your business.
                        <br><br>
                        In addition to assisting with company formation in the US, we strive to provide international
                        entrepreneurs with a variety of customized legal services.
                    </div>
                    <div slot="banner">
                        <img class="img-fluid" src="@/assets/global.png">
                    </div>

                </Splash>

            </div>
            <div class="odd-section py-3 py-md-5">
                <Exhibit title="Services For International Entrepreneurs">
                    <RowDeck :img="require('@/assets/service1.png')" title="Federal Employment Tax Number Applications">
                        A Federal Tax Identification Number is also known as an Employer Identification
                        Number
                        (EIN), and is used to identify a US business entity. All EIN applications (mail,
                        fax,
                        electronic) must disclose the name and Taxpayer Identification Number (SSN, ITIN, or
                        EIN) of the true principal officer. AILaw helps international entrepreneurs apply for a Federal
                        Employment Tax Number with no pre-existing Taxpayer Identification Number needed.

                    </RowDeck>
                    <RowDeck :img="require('@/assets/service2.png')" title="US Business Bank Account Setup">
                        A separate business bank account within the US will be required when doing business with most US
                        companies. Typically, opening bank accounts within the US will strictly require in-person
                        verification, which means the signer of the business account must be present at the bank in
                        order to open the account. AILaw works with major US banks to help set up your business account
                        prior to your physical presence in the country.
                    </RowDeck>
                    <RowDeck :img="require('@/assets/service3.png')" title="Delaware C-Corporation Formation and
                                    Virtual Office">
                        Out of the 50 states of the US, the state of Delaware is the best option for international
                        entrepreneurs to form their C-Corporation entities in. Delaware’s business laws are some of the
                        most flexible in the country. The Delaware Court of Chancery focuses solely on business law and
                        uses judges instead of juries. For companies formed in Delaware, there is no state corporate
                        income tax if the company is not physically doing business in the state. AILaw will help you
                        establish your business in Delaware with convenient post-registration services such as a
                        registered agent and a virtual office.
                    </RowDeck>
                </Exhibit>
            </div>
            <div class="container py-md-5 py-3">
                <Exhibit title="International Entrepreneurs Packges">
                    <div class="card-deck mx-lg-5 text-center">
                        <Price package-name="Essential" default-fee="98" class="mx-4" state="DE" type="CCORP"
                               package="BASIC">
                            <ul class="packageItem mt-3 mb-4">
                                <li>Article of Incorporation in Delaware
                                </li>
                                <li>Federal Employment Identification Number
                                </li>
                                <li>Business Bank Account Setup
                                </li>
                                <li>Registered Agent Service
                                </li>
                                <li>Virtual Office (optional)
                                </li>
                                <li>Document Translation</li>
                            </ul>
                        </Price>
                        <Price package-name="Premium" default-fee="99" class="mx-4" state="DE" type="CCORP"
                               package="PREMIUM">
                            <ul class="packageItem mt-3 mb-4">
                                <li> All services in essential pack
                                </li>
                                <li> Company Bylaw
                                </li>
                                <li> Founder Stock Purchase Agreement
                                </li>
                                <li> Employee NDA
                                </li>
                                <li> Employee Handbook
                                </li>
                                <li> Offer Letter</li>
                            </ul>
                        </Price>
                    </div>
                </Exhibit>
            </div>
        </main>
    </div>
</template>

<script>
  import Splash from '@/components/Block/Splash'
  import Exhibit from '../components/Block/Exhibit'
  import RowDeck from '../components/Block/RowDeck'
  import Price from '../components/Price'

  export default {
    name: 'Home',
    data () {
      return {
        state: '',
        type: ''
      }
    },
    components: {Price, RowDeck, Exhibit, Splash},
    methods: {
      toPricing () {
        const params = {
          state: this.state,
          type: this.type
        };
        this.$router.push({
          path: '/pricing',
          query: params
        });
      }
    }
  }
</script>


<style scoped lang="scss">
    button {
        outline: none;
    }

    #care {
        text-align: center;
    }

    .slogan-text {
        font-size: 1.25rem
    }

    .sec-title {
        font-size: 1.5rem;
    }

    .step {
        border-bottom: 1px solid;
        width: fit-content;
        margin: 0 auto;
        cursor: pointer;
    }

    .large-text {
        font-size: 1.2rem;
    }

    @include media-breakpoint-up(lg) {

        .slogan-text {
            font-size: 1.25rem;
        }

        .sec-title {
            font-size: 2rem;
        }

        .large-text {
            font-size: 1.5rem;
        }

        .slogan-main {
            line-height: 1.75rem;
        }
    }

    @include media-breakpoint-up(sm) {
        #care {
            text-align: left;
        }
    }

    .hello {
        text-align: center;
    }

    .font-5 {
        font-size: 3rem;
    }

    .font-4 {
        font-size: 2rem;
    }

    .font-2 {
        font-size: 1rem;
    }

    .line-h-3 {
        line-height: 2rem;
    }

    .odd-section {
        background-color: rgba(235, 244, 247, 1.0);;
    }

    .feature {
        width: 100%;
        vertical-align: center;
    }

    a {
        font-weight: bold;
    }

    .featurette-heading {
        margin: 0 2rem .5rem;
    }

    .packageItem {
        padding: 10px 20px;
        text-align: left;
    }
</style>
