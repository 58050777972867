<template>
    <div class="card shadow-sm">
        <div class="card-header">
            <div class="sec-subhead my-0 font-weight-normal font-weight-bolder my-lg-3">{{packageName}}</div>
            <h1 v-if="!quote || !ready" class="card-title pricing-card-title colored font-weight-bolder font-italic">
                ${{defaultFee}}
                <small class="text-muted"> + State Fee</small>
            </h1>
            <div v-else>
                <h1 class="card-title pricing-card-title yellow font-weight-bolder font-italic colored"> ${{quote &&
                    quote.totalServiceFee}} </h1>
                <h3>
                    <small class="text-muted">{{state}} State Fee: ${{quote && quote.totalGovernmentFee}}</small>
                </h3>
                <h3>
                    <small class="text-muted">Total Fee: ${{quote && quote.totalFee}}</small>
                </h3>
            </div>
        </div>
        <div class="card-body">
            <button type="button" class="btn btn-lg font-weight-bolder" @click="go">Get Started</button>
            <slot></slot>
        </div>
    </div>
</template>

<script>
  import gql from 'graphql-tag'

  export default {
    name: "Price",
    props: ["state", "type", "package", "default-fee", "package-name"],
    methods: {
      go () {
        if (!this.ready) {
          this.$emit('validate')
        } else {
          const params = {
            plan: this.package,
            state: this.state,
            type: this.type
          };
          this.$router.push({
            path: '/check-out',
            query: params
          });
        }
      }
    },
    computed:{
      ready: function () {
        return this.state && this.type && this.package
      }
    },
    watch: {
      state:(v)=>{
        // eslint-disable-next-line
        console.log(v)
      },
      type:()=>{},
      package:()=>{},
    },
    apollo: {
      quote () {
        return {
          // gql query
          query: gql`query pricing($state: FormationState!, $type: FormationCompanyType!, $package: FormationPackage!) {
            quote: cart(package: {formPackage:$package, formState:$state, formCompanyType: $type }) {
                totalGovernmentFee
                totalServiceFee
                totalFee
            }
          }`,
          variables () {
            return {
              state: this.state,
              type: this.type,
              package: this.package
            }
          },
          skip () {
            return !this.ready
          },
          fetchPolicy: 'cache-first'
        }
      }
    }
  }
</script>

<style scoped lang="scss">
    .card-header {
        background-color: rgba(54, 77, 110, 1.0);
        color: white;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
    }

    .card {
        border-radius: 12px;
    }

    .sec-head {
        font-size: 1.5rem;
    }

    .sec-subhead {
        font-size: 1.125rem;
    }

    @include media-breakpoint-up(lg) {
        .sec-head {
            font-size: 2.625rem;
        }

        .sec-subhead {
            font-size: 2rem;
        }

        .sec-subsubhead {
            font-size: 1.625rem;
        }
    }

    .text-muted {
        color: white !important;
        font-size: 1.125rem;
    }

</style>
